export interface CalendarEvent {
  title: string;
  date: Date | null;
  location: string;
  mapLocation: string;
  raspis: string;
  prijava: string;
  qr: string;
  popis: string;
  rezultati: string;
  galerija: string;
}

interface Result {
  season: number;
  link: string;
}

export const results: Result[] = [
  {
    season: 2025,
    link: "",
  },
  {
    season: 2024,
    link: "https://drive.google.com/file/d/1rAnIrT_0S3neEFDHLYsTJiRvNUsKNh0A/view?usp=sharing",
  },
  {
    season: 2023,
    link: "https://drive.google.com/file/d/1iLrzd3UUhPpdQn3qx5iSw9YQiw4gm8cX/view",
  },
  {
    season: 2022,
    link: "https://drive.google.com/file/d/184dIFa-x86gTUnZu9XJed4OkNkImnkeC/view",
  },
  {
    season: 2021,
    link: "https://drive.google.com/file/d/1Kew3HwIt9_Y1SbOgqkC3IOga7kiAnA63/view",
  },
];

export const events: CalendarEvent[] = [
  //2021
  {
    title: "XCO ŠUMA STRIBOROVA",
    date: new Date(2021, 3, 11),
    location: "Slavonski Brod",
    mapLocation: "",
    raspis: "",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO KOPIKA",
    date: new Date(2021, 3, 25),
    location: "Osijek",
    mapLocation: "",
    raspis: "",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO PUT ŠARANA",
    date: new Date(2021, 4, 23),
    location: "Virovitica",
    mapLocation: "",
    raspis: "",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO LAPOVAC",
    date: new Date(2021, 5, 19),
    location: "Našice",
    mapLocation: "",
    raspis: "",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO KORAL",
    date: new Date(2021, 7, 22),
    location: "Orahovica",
    mapLocation: "",
    raspis:
      "https://drive.google.com/file/d/10nLZgUUQ3-lyh7lRMtU9Id8eu4k4dt0v/view?usp=sharing",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCM PETNJA",
    date: new Date(2021, 8, 5),
    location: "Slavonski Brod",
    mapLocation: "",
    raspis:
      "https://drive.google.com/file/d/1kkCKyWtdfEHcIZEgNAFUE0OaVzibo6-s/view?fbclid=IwAR0SDdBPnGwMbs-Lm0n3egedcFZUIKWJY6eV760d73Vvalx3X8jGquisOSA",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO STARA DRAVA",
    date: new Date(2021, 8, 19),
    location: "Donji Miholjac",
    mapLocation: "",
    raspis:
      "https://drive.google.com/file/d/1kDE_IDrfgkSiE--8Sj70O9qB-Fpfzw6U/view?usp=sharing",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCM SLATINA",
    date: new Date(2021, 8, 26),
    location: "Slatina",
    mapLocation: "",
    raspis:
      "https://drive.google.com/file/d/19r86h5JBXVeXBr1lXI1QF8x3DhiKEdqR/view?usp=sharing",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO ŠICANA",
    date: new Date(2021, 10, 7),
    location: "Požega",
    mapLocation: "",
    raspis:
      "https://drive.google.com/file/d/17wTitPmLf36bgWq_97e4xuh5XGIw9XeN/view?usp=drivesdk",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  //2022
  {
    title: "XCO LAPOVAC",
    date: new Date(2022, 3, 10),
    location: "Našice",
    mapLocation: "",
    raspis:
      "https://docs.google.com/document/d/1QfX_4Wy86vu4ciHT6eMyHoguh2vDHFjX/edit?usp=sharing&ouid=109344524586112651369&rtpof=true&sd=true",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO ŠUMA STRIBOROVA",
    date: new Date(2022, 3, 24),
    location: "Slavonski Brod",
    mapLocation: "",
    raspis:
      "https://docs.google.com/document/d/1Ri-7Zk-ZLzWFCnTI-t2ZXp0LRB314sXm/edit?fbclid=IwAR3i36t3HaXWAJqzLKD4odizsNfoTxxNIVlAXbxvkujFe3KNDA9-l2D9zBQ",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO PUT ŠARANA",
    date: new Date(2022, 4, 14),
    location: "Virovitica",
    mapLocation: "",
    raspis:
      "https://l.facebook.com/l.php?u=https%3A%2F%2Fapp.box.com%2Fs%2F1c9jn1weqgy7a3p6zbpmozhyp3y1bzst%3Ffbclid%3DIwAR12M9mQfWDF2Lj_qgs8nQBpSaGKE6posOkhsKJAcpx5d7VX5LiRps7A1z8&h=AT2MVmOMxnUY3IeuASnEuOypEN1Q6OKDB4NkBXF39dUBsct9-Z7qJs9MRUUIkPo3PZtEkEpd6RYbymlVdWfjfucKdc4u400q-SJmXipiSsXAV0vth-Z332-3YA8SUEKFEQA",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO LAPOVAC",
    date: new Date(2022, 5, 19),
    location: "Našice",
    mapLocation: "",
    raspis:
      "https://drive.google.com/file/d/1jFTmkJQCjID8Em8QQKY2HzQhBXyCZ8T-/view?usp=sharing",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO ŠICANA",
    date: new Date(2022, 5, 26),
    location: "Požega",
    mapLocation: "",
    raspis:
      "https://drive.google.com/file/d/1w3KV8rD_Tdjt8fjVSo-HnrcKQNSgnPnq/view?usp=sharing",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO KORAL",
    date: new Date(2022, 7, 28),
    location: "Orahovica",
    mapLocation: "",
    raspis: "https://www.docdroid.net/eKj8zoB/pravila-utrke-xco-koral-2022-pdf",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCM SLATINA",
    date: new Date(2022, 9, 2),
    location: "Slatina",
    mapLocation: "",
    raspis:
      "https://drive.google.com/file/d/1HRnHO1L0mtcZDHpyYJu-3AfKBUCNeD_T/view?usp=sharing",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO STARA DRAVA",
    date: new Date(2022, 8, 25),
    location: "Donji Miholjac",
    mapLocation: "",
    raspis:
      "https://drive.google.com/file/d/1Y9KWzBoMIiGpmrXJoKGazjfKEOEWCJz4/view?usp=sharing",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  //2023
  {
    title: "XCC MAILATH",
    date: new Date(2023, 8, 24),
    location: "Donji Miholjac",
    mapLocation: "",
    raspis:
      "https://drive.google.com/file/d/1hwe7QIRlnL4CScxoAg4jH5nxNyUXMF1n/view",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO KORAL",
    date: new Date(2023, 7, 27),
    location: "Orahovica",
    mapLocation:
      "https://www.google.com/maps/place/45%C2%B031'59.3%22N+17%C2%B051'58.9%22E/@45.533146,17.8652587,368m/data=!3m2!1e3!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d45.5331462!4d17.8663532?shorturl=1",
    raspis:
      "https://drive.google.com/file/d/1Crm4mTov6WooA9JWpXXRzCTeQw4sEbl0/view?usp=drive_link",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO ŠUMA STRIBOROVA",
    date: new Date(2023, 5, 18),
    location: "Slavonski Brod",
    mapLocation: "",
    raspis:
      "https://drive.google.com/file/d/1EPmz63D_Qi0_VcP68a2smt2GNKVO3D94/view?usp=share_link",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO LAPOVAC",
    date: new Date(2023, 4, 28),
    location: "Našice",
    mapLocation: "",
    raspis:
      "https://drive.google.com/file/d/1WvBtpuC4M_ElgxnLo_iH9oSiG3p8DD8u/view",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO PUT ŠARANA",
    date: new Date(2023, 4, 13),
    location: "Virovitica",
    mapLocation: "",
    raspis: "https://app.box.com/s/t126hjla7ix4hmg7l68lqswfv8ui7rnr",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO ŠICANA",
    date: new Date(2023, 3, 30),
    location: "Požega",
    mapLocation: "",
    raspis:
      "https://drive.google.com/file/d/1mCpN2v4K6NZZgAV_zAFZFT-GTPGYrk8V/view?fbclid=IwAR2utfhmVUEzTngEn7PZi-QxXya48Xro3S3Y92jHnV0cGGgiofNJIbd1B7o",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCM Slatina",
    date: new Date(2023, 3, 22),
    location: "Slatina",
    mapLocation: "",
    raspis:
      "https://drive.google.com/file/d/15hepn824_Egk_0aTu88zXLDc8RE7MdNu/view?usp=share_link",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO KOPIKA",
    date: new Date(2023, 2, 26),
    location: "Osijek",
    mapLocation: "",
    raspis:
      "https://l.facebook.com/l.php?u=https%3A%2F%2Fbit.ly%2F3WN5b6Z%3Ffbclid%3DIwAR1x2pgR2lXGY-ThjfeHIHFTrcundFp932CTHTm_7vLgaKE5CFCOgi7tu8Y&h=AT2pHqk78vNCyMi9s8H5OSjS6nE61SwKKVDb4SSsXHMhZK2DA7fXEd_BeT406Zf_FKhmbTwxJXVYHNQCwgGpM5lOfc_aZ2NyGp_aMPFAVzOSGu9G91YlirLytFKa9PmcOg&__tn__=q&c[0]=AT0zufDF02wGAJAmo3w630RSbAztLDYk64--imizLj3N5EBeSWWvM0AieNaJnNWweS6W0mEzAo4XMYipR7Gj5y1fNH_3XsnPz_XcMcIIwjVS71w8l8_h4nqy0cHzPFVhnBVqUkBsAoM0hzT_MzDWjCfoncW-",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  /// 2024
  {
    title: "XCO BANOVO BRDO",
    date: new Date(2024, 3, 7),
    location: "Popovac",
    mapLocation: "http://www.google.com/maps/place/45.797197,18.690396",
    raspis:
      "https://docs.google.com/document/d/e/2PACX-1vQhn0KvnBJNcev09lPlaXAZIOBprDKDlJFFOJiNehy3nDuHJAs8Q9AONJGQQ2hktZJYauXqdvYrmoxC/pub",
    prijava: "https://forms.gle/FfqhQp6R2M8EVUBn9",
    qr: "https://drive.google.com/file/d/116qWHDK3lvc8gc4TC0-VJmI0npJWZjZR/view?usp=sharing",
    popis:
      "https://docs.google.com/spreadsheets/d/1Kv2LaZkp4gc9b0XuRnVWfoBW84tQg39-ds5jKnEgzc0/edit?usp=sharing",
    rezultati:
      "https://drive.google.com/file/d/1IcUDeid_ReZ43zUcQqCrU2hBhfGxd86J/view?usp=sharing",
    galerija: "https://www.facebook.com/media/set/?set=a.943939994401318",
  },
  {
    title: "XCM SLATINA",
    date: new Date(2024, 3, 27),
    location: "Slatina",
    mapLocation: "https://maps.app.goo.gl/YZb26FNZ9zKAxFkA6",
    raspis:
      "https://drive.google.com/file/d/13Sb5SN-3EzAZdjr7livc0iewfnsQmR_g/view?usp=sharing",
    prijava: "https://forms.gle/aMk1qGVdK7fDGZCp7",
    qr: "https://drive.google.com/file/d/1GP_-IDF6JvDGRJ4WYqsGaisbjg6bbg8L/view?usp=sharing",
    popis:
      "https://docs.google.com/spreadsheets/d/1C3-35Zw6JmrezRm5hvyAYavWDZ-BPtSkf66tILAMstI/edit?usp=sharing",
    rezultati:
      "https://drive.google.com/file/d/1qjwzDYwFW_1xAobJsr0qSdtrFantCs-j/view?usp=sharing",
    galerija: "https://www.facebook.com/share/xxeJ1wcY2ZW3akNy/",
  },
  {
    title: "XCP PUT ŠARANA",
    date: new Date(2024, 5, 8),
    location: "Virovitica",
    mapLocation:
      "https://www.google.com/maps/place/Stadion+Podgorje,+33000,+Podgorje/data=!4m2!3m1!1s0x4767c140cfe98f41:0xda2211221bbefd10?utm_source=mstt_1&entry=gps&coh=192189&g_ep=CAESCjExLjEyOC4xMDEYACDXggMqUSw5NDIyMTEzNCw5NDIxMjQ5Niw5NDIwNzM5NCw5NDIwNzUwNiw5NDIxNzUyMyw5NDIxODY1Myw0NzA4NzExOCw0NzA4NDM5Myw5NDIxMzIwMEICSFI%3D",
    raspis: "https://app.box.com/s/07j9vzu25bgjfgcd1u9o9qlwl7c7m6pa",
    prijava: "https://forms.gle/d2wyCeoeUkoT5qXu6",
    qr: "https://drive.google.com/file/d/1ZFDumoCCXVvuIu_kCTV0YbeocCF9IzUX/view?usp=sharing",
    popis:
      "https://docs.google.com/spreadsheets/d/1Yz_4rTstNABpgw3nKUgWVSxH7w4uYUkL0rBb-elXlbI/edit#gid=175323010",
    rezultati:
      "https://drive.google.com/file/d/1xbqHwtXGGq70IM7dMyRuOhs7b7kQp19y/view?usp=sharing",
    galerija: "https://www.facebook.com/share/4R7rTfn9GxTv6jhk/",
  },
  {
    title: "XCO LAPOVAC",
    date: new Date(2024, 4, 26),
    location: "Našice",
    mapLocation: "https://maps.app.goo.gl/PrBZfmFVwbfkDg7m9",
    raspis:
      "https://drive.google.com/file/d/16BAq6ee7ejS3dn2hq13bIk6uqrRFGpmp/view",
    prijava: "https://forms.gle/MA3JxhPuFTbLFEbG9",
    qr: "https://drive.google.com/file/d/11wlks_cTW0daKU3HgRN5vzwtcmwo_x40/view?usp=drive_link",
    popis:
      "https://docs.google.com/spreadsheets/d/1uxulT-2KP4-zVQ4TX3JEz4PMKEngTS4mSfWd62-Kwn0/edit?usp=sharing",
    rezultati:
      "https://drive.google.com/file/d/1KhTOf-LCtJijtxx34flyVzSxrdgry78_/view?usp=sharing",
    galerija: "https://www.facebook.com/share/rkqX99zDmbrAsd8v/",
  },
  {
    title: "XCO KORAL",
    date: new Date(2024, 8, 29),
    location: "Orahovica",
    mapLocation: "https://maps.app.goo.gl/PYsF7TDwqge6hZbNA",
    raspis:
      "https://drive.google.com/file/d/1_NDszcVwUL62K98g7RiZL22QwUeXBAlP/view?usp=sharing",
    prijava: "https://forms.gle/yy3xQooeNivQAE3CA",
    qr: "https://drive.google.com/file/d/15DvaeCDPh0Fd6RwawpsnJtOxWvWtFRWy/view?usp=sharing",
    popis:
      "https://docs.google.com/spreadsheets/d/18j0JqPIXtO6Nf4r_V1ObjUBA6uE1xfN_UubdFsfmWMc/edit?usp=sharing",
    rezultati:
      "https://drive.google.com/file/d/15EpIkcUtsvQKe7t38CnvVgtG9qe-oAn_/view?usp=sharing",
    galerija:
      "https://www.facebook.com/groups/sbbliga/permalink/9042761349085154/",
  },
  {
    title: "XCO ŠUMA STRIBOROVA",
    date: new Date(2024, 8, 8),
    location: "Slavonski Brod",
    mapLocation: "https://goo.gl/maps/swEWTQ6VJp4twHQM8",
    raspis:
      "https://drive.google.com/file/d/1wqqCekxI5EO5OOCeUMgFx2BLPF8zL9Ju/view?usp=sharing",
    prijava: "https://forms.gle/wtNaLgzK2MLiKfrY7",
    qr: "https://drive.google.com/file/d/1cGtUIciTPaFCxhJ2bmOouM0fc6raP_S1/view?usp=sharing",
    popis:
      "https://docs.google.com/spreadsheets/d/1N0oQrIu2R-0d9uPGw27xF7fcO6QP7757mUVR7NQU5vg/edit?usp=sharing",
    rezultati:
      "https://drive.google.com/file/d/1A_U-L8eABMZl-cWIdwgHieGGssWLQnol/view?usp=sharing",
    galerija:
      "https://drive.google.com/drive/folders/1lsnLJy-_7sUZ5E9puVY2fPeNTJfkejSt?fbclid=IwY2xjawFQnz9leHRuA2FlbQIxMQABHTy0V7OWAyZIrq5LwGAMY6YOIcc2MyUWO69200ZVR5PbduZ7DbRyXcBfTw_aem_tL0GroQXy9MszGmOGJ_V-A",
  },
  {
    title: "XCO STARA DRAVA",
    date: new Date(2024, 8, 22),
    location: "Donji Miholjac",
    mapLocation: "https://maps.app.goo.gl/3DFY56fnj3jwjJS28",
    raspis:
      "https://docs.google.com/document/d/1zotp_Qv2i0fIiXwBhtcTIQ2rFubXX71A/edit?usp=sharing&ouid=101141187721478309693&rtpof=true&sd=true",
    prijava: "https://forms.gle/tz8T8HLWtFXVrZeE9",
    qr: "https://drive.google.com/file/d/1doAPp1wEka--dReyqDrJdz3F5wQh9X4O/view?usp=drive_link",
    popis:
      "https://docs.google.com/spreadsheets/d/11KckPmcV9KoWmCPGoi6C-h4bSvNFqqxlrXZ1jem44pE/edit?usp=drive_link",
    rezultati:
      "https://drive.google.com/file/d/1F_IkGIjsqmwxVhuX4JXSOTpFsLkE4Fcc/view?usp=sharing",
    galerija:
      "https://www.facebook.com/media/set/?vanity=ivicakerep23&set=a.10227281814850494",
  },
  {
    title: "XCO TVRĐAVA",
    date: new Date(2024, 9, 26),
    location: "Slavonski Brod",
    mapLocation: "https://maps.app.goo.gl/bXmg4DWHSgaF1G4D9",
    raspis:
      "https://docs.google.com/document/d/1vkcVFz4HqTkUQdyM_K49KLTdlghm5kS0/edit?usp=drive_link&ouid=101141187721478309693&rtpof=true&sd=true",
    prijava: "https://forms.gle/KH7kbKKZwFqxfx1y7",
    qr: "https://drive.google.com/file/d/1f2eaVkAcXDnZh7HT_7-kWoTRF4fJzsf_/view?usp=sharing",
    popis:
      "https://docs.google.com/spreadsheets/d/1QG6_cCREvKLpor7aphbYZ4nCkGpkLC6Ihk1zwtPkPCM/edit?usp=sharing",
    rezultati:
      "https://drive.google.com/file/d/1BrxW6NtB4PRpfq5n2xomoAOWZo1Mtlg6/view?usp=drive_link",
    galerija:
      "https://www.facebook.com/media/set?vanity=origidigi&set=a.8691535200902428",
  },
  // 2025
  {
    title: "XCO BANOVO BRDO",
    date: new Date(2025, 3, 6),
    location: "Popovac",
    mapLocation: "http://www.google.com/maps/place/45.797197,18.690396",
    raspis: "https://docs.google.com/document/d/e/2PACX-1vRd7e_39--3F-cbMOqSHp8ofurQHlugcF-k8Zk0CxgxfrILnctrr_B3OOTfCd8GI2LQZdXybc-ZUU80/pub",
    prijava: "https://forms.gle/RK5vhaMyq2zgidQ29",
    qr: "https://drive.google.com/file/d/116qWHDK3lvc8gc4TC0-VJmI0npJWZjZR/view?usp=drive_link",
    popis: "https://docs.google.com/spreadsheets/d/1wSI32nNj4gfoxwG_dj1RgUUiPI1lx2FZzppDud1rAhg/edit?usp=sharing",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCM JAVORICA",
    date: new Date(2025, 3, 13),
    location: "Slatina",
    mapLocation: "https://maps.app.goo.gl/YZb26FNZ9zKAxFkA6",
    raspis: "https://drive.google.com/file/d/11ZzvcTdgw3jbfYklMnPSU6YUkBH0CZM7/view?usp=sharing",
    prijava: "https://forms.gle/Trr4TM3vXp5ACYMs9",
    qr: "https://drive.google.com/file/d/1GP_-IDF6JvDGRJ4WYqsGaisbjg6bbg8L/view?usp=drive_link",
    popis: "https://docs.google.com/spreadsheets/d/1i2gSnA-X6bBv1Y-ZVqW2tPd1WxggjJ8mqu6L3XxNsMU/edit?usp=sharing",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCP PUT ŠARANA",
    date: new Date(2025, 5, 7),
    location: "Virovitica",
    mapLocation: "",
    raspis: "",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO LAPOVAC",
    date: new Date(2025, 4, 25),
    location: "Našice",
    mapLocation: "https://maps.app.goo.gl/PrBZfmFVwbfkDg7m9",
    raspis: "https://drive.google.com/file/d/1Gh_m6EYnSVg1mz5TtI2h6Hkd34uOhNXE/view?usp=drive_link",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO KORAL",
    date: new Date(2025, 8, 6),
    location: "Orahovica",
    mapLocation: "https://maps.app.goo.gl/PYsF7TDwqge6hZbNA",
    raspis: "",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO ŠUMA STRIBOROVA",
    date: new Date(2025, 5, 22),
    location: "Slavonski Brod",
    mapLocation: "https://goo.gl/maps/swEWTQ6VJp4twHQM8",
    raspis: "",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO STARA DRAVA",
    date: new Date(2025, 8, 21),
    location: "Donji Miholjac",
    mapLocation: "https://maps.app.goo.gl/3DFY56fnj3jwjJS28",
    raspis: "",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO TVRĐAVA",
    date: new Date(2025, 9, 26),
    location: "Slavonski Brod",
    mapLocation: "https://maps.app.goo.gl/bXmg4DWHSgaF1G4D9",
    raspis: "",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO KALVARIJA",
    date: new Date(2025, 3, 27),
    location: "Pakrac",
    mapLocation: "",
    raspis: "",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO Gradiška",
    date: new Date(2025, 4, 11),
    location: "Gradiška",
    mapLocation: "",
    raspis: "",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
  {
    title: "XCO POŽEGA",
    date: null,
    location: "Požega",
    mapLocation: "",
    raspis: "",
    prijava: "",
    qr: "",
    popis: "",
    rezultati: "",
    galerija: "",
  },
];
